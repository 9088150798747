.icondaumen {
    width: 50px;
    height: auto;
    margin-top: calc(-1.5rem - 25px);
    margin-bottom: 1rem;    


    @media(min-width: 768px) {
        width: calc(100% - 4rem);
        min-width: 50px;
        max-width: 500px;
        margin: 1.5rem 2rem;
        margin-top: 0rem;
    }
}