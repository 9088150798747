@import "../../scss/variables.scss";

.footer {
    background-color: $light;
}

.strasse {
    background-image: url(../../images/footer_strasse.svg);
    background-repeat: repeat-x;
    background-size: auto 35%;
    background-position: bottom;
    margin-top: 4rem;

    @media (min-width: 992px) {
        margin-top: 6rem;
    }

    img {
        height: 9rem;
        margin-bottom: 2%;
        width: auto;

        @media(min-width:576px){
            height: 15rem;
        }         

    }

    .rownowrap {
        flex-wrap: nowrap;
    }

    .footertitle, #footertitle {
        font-size: 24px;
        font-family: $headings-font-family-medium;
        padding-top: 0;
        padding-left: 1rem;
        color: $nav-link-color;
        flex-shrink: 1;

        @media(min-width:576px){
            font-size: 36px;
            padding-top: 0.5rem;
            padding-left: 1.5rem;
        }  

        @media (min-width: 768px) {
            font-size: 50px;
        }
    }
}



