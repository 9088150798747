@import "../../scss/variables.scss";
@import "../../scss/animations.scss";

.games {
    text-align: center;
}

.game {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    background-color: $light;
    border-radius: 0.5rem;
    // min-width: 300px;
    max-width: calc(350px + 1rem);
    height: 100%;
    box-shadow: 0 0 8px 0 rgba(0,0,0,0.25);
    transition: 500ms;
    // animation: heartbeat 1.5s ease-in-out infinite both;

    &.animate {
        animation: wobble-hor-bottom 800ms both;
    }

    .tileText {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        flex: 1 0;
        
        h2 {
            font-size: 1.5rem;
        }
    }

    &:hover {
        background-color: $swtBlue;
        color: $light;
        transition: 500ms;
        // animation: wobble-hor-bottom 800ms both;
    }

    strong {
        margin-top: 1rem;
        // font-weight: bold;
    }

    p {
        margin: 0;
        font-family: MavenPro-SemiBold;
    }

    
    img {
        width: 100%;
        height: auto;
    }
}