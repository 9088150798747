@import "../../../scss/variables.scss";

.slideArea {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    background-color: $swtBlueLighter;
    border: 3px solid $swtBlue;
    border-radius: 0.5rem;
    box-shadow: 0 0 8px 1px rgb(0 0 0 / 30%);
    overflow: hidden;
    height: 50vh;

    @media(min-width: 768px){
        height: 66vh;
    }

    .slideAreaRow {
        padding: 1rem 0;
        overflow-y: auto;
        margin-top: 0;
        margin-bottom: 0;
        transition: 500ms;
    }    
    
    .slideBtn {
        display: none;
        position: absolute;
        left: calc(50% - 24px);
        align-items: center;
        width: 48px;
        height: 48px;
        margin: 0 !important;
        z-index: 15;
        
        svg {
            width: 100%;
            height: auto;
        }
    }

    &.scrollTop{

        .slideBtn {        
            &.top {
                display: flex;
                top: 0.5rem;
            }
        }

        .slideAreaRow {
            &::before {
                content: "";
                background: linear-gradient(180deg, $swtBlueLighter 0%, $swtBlueLighter 33%, rgba(255,255,255,0) 100%);
                position: absolute;
                top: 0;
                left: 0;
                width: calc(100%);
                height: calc(3rem + 5%);
                pointer-events: none;
                z-index: 14;
            }
        }
    }

    &.scrollBottom {

        .slideBtn {        
            &.bottom {
                display: flex;
                bottom: 0.5rem;
            }
        }

        .slideAreaRow { 
            &::after {
                content: "";
                background: linear-gradient(0deg, $swtBlueLighter 0%, $swtBlueLighter 33%, rgba(255,255,255,0) 100%);
                position: absolute;
                bottom: 0;
                left: 0;
                width: calc(100%);
                height: calc(3rem + 5%);
                pointer-events: none;
                z-index: 14;
            }
        } 
    }
}




