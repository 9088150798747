@import "../../scss/variables.scss";

.subnavi {
    width: 100%;
    max-height: 100px;
}

.ganze_breite {
    width: 100%;
}

.nopadding {
    padding: 0 !important;
}

span {
    display: none;


    &.aufklappbar {
        content: "";
        background-image: url("../../images/icon_pfeil_blau_oben.svg");
        background-size: 15px auto;
        background-position: center;
        background-repeat: no-repeat;
        display: inline-block;
        width: 50px;
        border-left: 1px solid $nocheingrau;
        flex-shrink: 0;
        cursor: pointer;

        &.offen {
            background-image: url("../../images/icon_pfeil_blau_unten.svg");
        }
    }          

}


#ebene1 {
    list-style: none;
    display: none;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    position: relative;
    padding: 0;
    margin: 0;


    @media(min-width:768px) {
        display: flex;
    } 

    > li {
        &:first-of-type {
            margin-right: 2rem;

        }
    }

    li {
    
        &:hover, &:focus-within, &:focus {
            text-decoration: underline; 
            color: $swtBlue;
            background-color: white;

            a {
                color: $swtBlue;
                font-size: 24px;
            }
            
            ul.ebene2 {
                display: flex;
                flex-direction: row;
                list-style: none;
                position: absolute;
                background-color: white;
                color:  $swtBlue;
                width: 100%;
                overflow: visible;
                left: 0;
                padding: 1rem;
                justify-content: center;
                border: 1px solid $swtBlue;
                border-top: none;

                &.ebene3 {
                    li {
                        padding: 0rem 0.75rem;

                        a {
                            font-size: 18px;
                            padding: 0.25rem 0rem;
                        }  
                        
                        &:hover, &:focus-within, &:focus {
                            text-decoration: underline;
                            background-color: $swtBlue;
                            color: white;

                            a {
                                color: white;
                                font-size: 18px;
                            }

                        }                                         

                    }

                }


                li {
                    display: flex;
                    flex-direction: column;
                    list-style: none;
                    width: 25%;
                    border-right: 1px solid $nocheingrau;

                    &:last-of-type {
                        border-right: none;
                    }

                    &:hover, &:focus-within, &:focus {
                        text-decoration: none;
                    }

                    ul.ebene3 {
                        display: flex;
                        flex-direction: column;
                        

                        li {
                            width: 100%;
                            border-right: none;
                            padding: 0rem 0.75rem;


                            &:hover, &:focus-within, &:focus {
                                text-decoration: underline;
                                background-color: $swtBlue;
                                color: white;

                                a {
                                    color: white;
                                    font-size: 18px;
                                }

                            }
                        }

                        a {
                            font-size: 18px;
                            padding: 0.25rem 0rem;
                        }

                    }
                }

            }
        }

        a {
            color: white;
            font-size: 24px;
        }

        ul {
            display: none;
            padding-left: 0px;
        }
               
    }
}

li.ohneborder {
    border: 0 !important;
}

.zwischenueberschrift {
    color: black;
    padding: 0rem 0.75rem;
    font-family: Saira-Medium;
    font-size: 1.2rem;
    margin-bottom: 1rem;
}

.zwischenueberschriftburger {
    color: $nav-link-color;
    padding: 0.5rem 1rem 0.5rem 2rem;
    font-family: Saira;
    // border-bottom: 1px solid $nav-link-color;
    display: flex;
    justify-content: space-between;

    &+ span {
        border-left: 0;
    }
}

.playbutton {
    li {
        a {
            &:before {
                content: url("../../images/icon_play_blau.svg");
                width: 25px;
                height: 25px;
                margin-right: 0.5rem;
                display: inline-block;
                flex-shrink: 0;
                align-self: center;
            }            
        }
    }
}






