@import "./animations.scss";

header {
    font-family: $headings-font-family;
    position: fixed;
    width: 100%;
    // min-height: 88px;
    z-index: 105;
}

main {
    flex-grow: 1;
    margin-top: 68px;

    @media(min-width: 768px) {
        margin-top: 88px;
    }
}

main.abstand_oben {
    @media(min-width: 768px) {
        margin-top: 140px;
    }
}

header, main, footer {
    flex-shrink: 0;
}

a.aktiv {
    text-decoration: underline;
}

.nopadding {
    padding: 0 !important;
}

.magenta {
    color: $swtMagenta;
}

.blue {
    color: $swtBlue;
}


.bg_lightgray {
    background-color: $lightgray;
    margin-bottom: 1.5rem;
    padding: 1.5rem 0;
    margin-top: 0.5rem;
}


/* Header-images */
.eltern {
    background-image: url('../images/menschen_eltern.png');
}

.abstand_ou {
    padding-top: 0rem !important; 
    padding-bottom: 0.5rem !important;

    @media(min-width: 992px) {
        // padding-top: 2rem !important; 
        padding-bottom: 2rem !important;       
    }
}

.App {
    font-family: $font-family-base;
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    &.games {
        background-image: url("../images/hintergrund-blur_sm.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        
        @media(min-width: 1200px){
            background-image: url("../images/hintergrund-blur.jpg");
        }

        @media(min-width: 1400px){
            background-image: url("../images/hintergrund-blur_lg.jpg");
        }

        h1,h2,h3,h4,h5,h6 {
            font-family: $children-font-family;
        }
    }

    &.general {
        h1 {
            font-family: $headings-font-family-medium;
            margin-bottom: 1.5rem;
            margin-top: 2rem;


            @media (min-width: 992px) {
                margin-top: 4rem;
            }
        }

        .intro, p.intro {
            color: $introColor;
            font-size: 1.3rem;
            font-family: $headings-font-family;
    
        }
        
        .einzug {
            padding-left: 1.5rem;
            font-size: 1.3rem;
        }
    
        p  {
            font-size: 1.1rem;
            margin-bottom: 2rem;

            &.besonders {

                @media (min-width: 992px) {
                    margin-bottom: 4rem;
                }            
            }
            
            &.vorliste {
                margin-bottom: 1rem;
            }   
            
            strong {
                font-family: $font-family-base-bold;
                font-weight: normal;
            }
        }
    
        p.ohneabstand {
            margin-bottom: 0rem;
        }
    
        .reverseorder {
            flex-direction: column-reverse;
            margin-bottom: 2.5rem !important;
    
    
            @media(min-width: 768px) {
                flex-direction: row;
            }

            p:not(.ohneabstand) {

                @media (min-width: 992px) {
                    margin-bottom: 4rem;
                }            
            }            
        }
    
        .mobileabstandunten {
            margin-bottom: 2rem;
        
            @media(min-width: 768px) {
                margin-bottom: 0rem;
            }
        }    
    
        ol, ul {
            font-size: 1.1rem;
            margin-bottom: 2rem;          
        }
    
        ul.green_circle {
            list-style: none;
            margin-top: 2rem;
    
            li {
                display: flex;
                margin-bottom: 1rem;
    
                &:before {
                    content: "\2022";
                    color: $swtGreen;
                    // width: 2rem;
                    font-size: 4rem;
                    margin-left: -2rem;
                    line-height: 1.5rem;
                    margin-right: 1rem;
                }
    
    
            }
        }

            // New Button styles
        a {            
            .buttonkinder {
                border-color: $swtMagenta;
                div {
                    &:first-of-type {
                        background-color: $swtMagenta;

                        img {
                            content: url("../images/icon_kinder_kreis.svg");
                        }
                    }
                }

            
            }

            .buttoneltern {
                border-color: $swtBlue;
                div {
                    &:first-of-type {
                        background-color: $swtBlue;

                        img {
                            content: url("../images/icon_eltern_kreis.svg");
                        }
                    }
                }

            }

            .buttoninfo {
                border-color: $swtGreen;

                div {
                    &:first-of-type {
                        background-color: $swtGreen;

                        img {
                            content: url("../images/icon_info_kreis.svg");

                        }

                        p {
                            margin: 0;
                        }
                    }
                }    


            }

            .buttonplay {
                border-color: $swtBlue;
                // margin-bottom: 2rem;

                div {
                    &:first-of-type {
                        background-color: $swtBlue;

                        img {
                            content: url("../images/icon_play_kreis.svg");

                        }

                        p {
                            margin: 0;
                        }
                    }
                }   
                           

            }

            &:hover, &:focus-within, &:focus {
                .buttonkinder {
                    background-color: $swtMagentaHover;                    
                }

                .buttoneltern {
                    background-color: $swtBlueHover;                    
                }

                .buttoninfo {
                    background-color: $swtGreenLighter;                    
                }

                .buttonplay {
                    background-color: $swtBlueLighter;
                }


            }
        }
      
    }

    // BurgerButton-styles
    .open {
        padding-right:  calc(var(--bs-gutter-x) * 0.5);
        padding-top: 0rem;
        padding-bottom: 0rem;

        span {
            display: none;
        }
    }
    
    .closed {
        div {
            display: none;
        }
    }
}

.spinner-icon{
    animation: spin 2s infinite;
    width: 48px;
    height: 48px;
    margin-right: 1rem;
}

.bildnachweis {
    height:80px;
}