@import "../../../scss/variables.scss";

.tileWrapper {
    
    // background-color: $light;
    // padding: 1rem;
    border-radius: 1rem;

    h3 {
        color: $dark;
    }

    .tiles {
        display: flex;
        width: 100%;
        justify-content: center;
        flex-wrap: wrap;
    }
    
    .tile {
        position: relative;
        // display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin: 0.5rem;
        // background-color: $light;
        color: $swtMagenta;
        border-radius: 0.5rem;
        width: 48px;
        height: 48px;
        // flex: 1;
        text-decoration: none;

        @media(min-width: 320px){
            width: calc(20% - 1rem);
            height: auto;
        }

        @media(min-width: 576px){
            width: 75px;
            height: 75px;
        }

        @media(min-width: 768px){
            width: 56px;
            height: 56px;

            .tileText {
                width: calc(2.5rem + 0.5vw);
                height: calc(2.5rem + 0.5vw);
            }
        }

        @media(min-width: 1400px){
            width: 96px;
            height: 96px;

            .tileText {
                width: calc(3rem + 0.5vw);
                height: calc(3rem + 0.5vw);
            }
        }

        &.correct {
            
            border-radius: 0.25rem;
            border: 4px solid $swtMagenta20;

            &.active {
                border-color: $swtMagenta80;
            }

            .tileText {
                color: $success;
            }
        }

        &.none, &.false{
            aspect-ratio:1/1;
            border: 4px dotted $swtMagenta20;
            border-radius: 0.25rem;
            display: flex;
            padding: 0.5rem;

            &.active {
                border-color: $swtMagenta80;
            }

            .repeat {
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: calc(0.8rem + 0.25vw);

                svg {
                    width: 24px;
                    height: 24px;
                }
            }

            .tileText {
                bottom: 3%;
                right: calc(50% - (1.5rem + 0.25vw));
            }
        }

        &.false{
            justify-content: center;
            cursor: pointer;

            svg {
                width: 36px;
                height: 36px;
            }

            .tileText {
                color: $danger;
                bottom: calc(3%);
                right: calc(5%);

                // svg {
                //     margin: 0;
                // }
            }
        }

        &.none {
            justify-content: center;
            
            svg {
                width: 24px;
                height: 24px;
            }

            .tileText {
                color: $swtBlue;
                display: none;
            }
        }

        .tileHeading {
            font-size: 0.9rem;
            // font-weight: bold;
            display: flex;
            justify-content: center;
            // align-items: center;
            text-align: center;
            margin: 1rem 0;
        }

        .tileText {
            display: none;
            align-items: center;
            justify-content: center;
            color: $success;
            position: absolute;
            bottom: calc(3%);
            right: calc(5%);
            background: $light;
            border-radius: 50%;
            z-index: 5;
            color: $swtBlue;
            
            @media(min-width: 768px){
                display: flex;
                width: 24px;
                height: 24px;
                padding: 1px;
            }

            @media(min-width: 1400px){
                display: flex;
                width: 32px;
                height: 32px;
                padding: 2px;
            }

            svg {
                width: 100%;
                height: auto;
            }

            p {
                margin: 0;
            }
        }

        // .imgWrapper {
            
            img {
                width: 100%;
                height: auto;
            }
        // }
    }
}

