@import "../scss/variables.scss";

.gamesWrapper {

    .col {
        max-width: 1272px;
        margin: auto;
    }

    .textWrap {
        background-color: $light;
        padding: 1rem;
        border-radius: 0.5rem;
    }

    h3 {
        font-family: $font-family-base !important;
        font-size: 1.25rem;
    }

    .flipBoxFront {
        max-width: 300px;
        width: 100%;
        height: 56px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $swtBlue;
        margin: auto;
        border-radius: 0.5rem;

        h1 {
            color: $light;
            margin: 0;
        }
        
        @media(min-width:576px){
            background-color: unset;
            background-image: url("../images/trapez-blue.svg");
            background-position: center;
            background-repeat: no-repeat;
            background-size: 100%;
            max-width: 300px;
            width: 100%;
            height: 96px;
            border-radius: 0;
        }
    
    }
    
}
