@import '../../scss/variables.scss';

.faqs {

    &:first-of-type {
        h3 {
            border-top: 1px solid $nocheingrau;
        }
    }

    h3 {
        padding: 1.5rem 0rem;
        border-bottom: 1px solid $nocheingrau;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items:center;

        &::after {
            // content: url("../../images/icon_pfeil.svg");
            content: "";
            background-image: url("../../images/icon_pfeil.svg");
            background-size: auto 15px;
            background-repeat: no-repeat;
            background-position: center center;
            transform: rotate(90deg);
            background-color: $swtBlue;
            color: white;
            width: 25px;
            height: 25px;
            display: block;
            border-radius: 13px;
            // font-size: 18px;
            font-weight: bold;
            transition: transform 1s ease;
            text-align: center;
            flex-shrink: 0;
        }
    }

    > div {
        display: none;
        height: 0%;
        transition: height 3s ease;
        margin-bottom: 2rem;
    }


    &.faqactive {


        > div {
            display: inline-block;
            height: 100%;
        }

        h3 {
            &::after {
                transform: rotate(-90deg);
            }
        }
    }    

}

.abschluss {
    margin-top: 2rem;
}