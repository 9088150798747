.karussell {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;


    @media(min-width: 576px) {
        width: 80%;
    }

    a {
        text-decoration: none;
        display: flex;
        flex-direction: column;
        justify-content: center;        
    }

    .links, .rechts {
        // color: white;
        width: 15px;
        height: 15px;
        line-height: 1;
        background-image: url("../../images/icon_pfeil.svg");
        background-repeat: no-repeat;
        background-position: right top;

        @media(min-width: 420px) {
            width: 20px;
            height: 20px;
            line-height: unset;
        }
    }

    .links {
        margin-right: 0.25rem;
        transform: rotate(180deg);

        @media(min-width: 420px) {
            margin-right: 1rem;
        }
    }

    .rechts {
        margin-left: 0.25rem;

        @media(min-width: 420px) {
            margin-left: 1rem;
        }        
    }

    .punktleiste {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-grow: 1;

        .punkt, .punktaktiv {
            border: 2px solid white;
            width: 15px;
            height: 15px;
            margin: 0.1rem;
            border-radius: 10px;

            @media(min-width: 420px) {
                width: 20px;
                height: 20px;
                margin: 0.25rem;
            }
        }

        .punktaktiv {
            background-color: white;
        }
    }
}