@font-face {
    font-family: Caveat;
    src: url("../fonts/CaveatBrush-Regular.ttf") format("truetype");
}

@font-face {
    font-family: Saira;
    src: url("../fonts/Saira-Regular.ttf") format("truetype");
}

@font-face {
    font-family: Saira-Medium;
    src: url("../fonts/Saira-Medium.ttf") format("truetype");
}

@font-face {
    font-family: Saira-Bold;
    src: url("../fonts/Saira-Bold.ttf") format("truetype");
}

@font-face {
    font-family: MavenPro;
    src: url("../fonts/MavenPro-Regular.ttf") format("truetype");
}

@font-face {
    font-family: MavenPro-SemiBold;
    src: url("../fonts/MavenPro-SemiBold.ttf") format("truetype");
}

$swtMagenta: rgb(220, 37, 125);
$swtMagenta80: rgba(220, 37, 125, 0.8);
$swtMagenta20: rgba(220, 37, 125, 0.2);
$swtMagentaLight: #E35097;
$swtMagentaLighter: #F8D3E5;
$swtMagentaHover: #EE92BE;

$swtGreen: rgb(174, 201, 11);
$swtGreen80: rgba(174, 201, 11, 0.8);
$swtGreen20: rgba(174, 201, 11, 0.2);
$swtGreenLight: #BED33B;
$swtGreenLighter: #EFF4CE;

$swtBlue: rgb(0, 126, 182);
$swtBlue80: rgba(0, 126, 182, 0.8);
$swtBlue20: rgba(0, 126, 182, 0.2);
$swtBlueLight: #3397C4;
$swtBlueLighter: #CCE5F0;
$swtBlueHover: #80BFDB;

$primary: $swtBlue;
$secondary: $swtMagenta;
$alternative: $swtGreen;
$success: $swtGreen;
$danger: #FE4E00;
$warning: #E0CA3C;
$info: #048A81;
// $light: #EFF9F0;
$light: #ffffff;
$light50: rgba(255,255,255,0.5);
$light80: rgba(255,255,255,0.8);
$dark: #310A31;
$muted: #707070;
$white: #ffffff;
$introColor: #1E2846;
$lightgray: #F9F9F9;
$nocheingrau: #707070;
$transparent: transparent;
$nav-link-color: #707070;
$tafelfarbe: rgb(20,50,50);
$cardgray: #CCCCCC;

$font-family-base: MavenPro, "Helvetica Neue", Arial;
$font-family-base-bold: MavenPro-SemiBold, "Helvetica Neue", Arial;
$headings-font-family: Saira, "Helvetica Neue", Arial;
$headings-font-family-medium: Saira-Medium, "Helvetica Neue", Arial;
$headings-font-family-bold: Saira-Bold, "Helvetica Neue", Arial;
$children-font-family: Caveat, "Helvetica Neue", Arial;
