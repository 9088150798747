@import "../../../scss/variables.scss";

.slideArea {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    // border-radius: 0.5rem;
    padding: 0 1rem;
    // margin: 1em 0;
    background-color: $swtBlueLighter;
    // overflow: hidden;

    &.activeRow {
        padding: 0;
    }

    .col {
        padding: 0 !important;
    }
    
    .slideAreaRow {
        display: flex;
        flex-wrap: nowrap;
        overflow: auto;
        padding: 0.5rem 0;


        &.activeRow {
            border: 2px solid $primary;
            border-radius: 1rem;
            padding: 0.5rem;
        }
    }    
    
    .slideBtn {
        display: none;
        position: absolute;
        top: calc(50% - 24px);
        align-items: center;
        width: 48px;
        height: 48px;
        margin: 0 !important;
        z-index: 5;

        svg {
            // color: $light;
            width: 100%;
            height: auto;
            // padding: 2px;
        }
    }
    
    &.scrollLeft{
    
        .slideAreaRow {
            padding-left: 20%;
        
            &::before {
                content: "";
                background: linear-gradient(90deg, $swtBlueLighter 0%, $swtBlueLighter 50%, rgba(255,255,255,0) 100%);
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: calc(3rem + 5%);
                pointer-events: none;
                z-index: 4;
            }
        }

        .slideBtn {
            &.left {
                display: flex;
                left: 0.5rem;
            }
        }
    }

    &.scrollRight{
    
        .slideAreaRow {
            padding-right: 20%;
        
            &::after {
                content: "";
                // background: linear-gradient(-90deg,#fff,hsla(0,0%,100%,0));
                background: linear-gradient(-90deg, $swtBlueLighter 0%, $swtBlueLighter 80%, rgba(255,255,255,0) 100%);
                position: absolute;
                top: 0;
                right: 0;
                height: 100%;
                width: calc(3rem + 5%);
                pointer-events: none;
                z-index: 4;
            }
        }

        .slideBtn {        
            &.right {
                display: flex;
                right: 0.5rem;
            }
        }
    }
}




