@import "../scss/variables.scss";


.boxes {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}


#tafel {
    background-color: $tafelfarbe;
    background-image: url('../images/tafel_verkehr.jpg');      
    display: flex;
    flex-direction: column;
    height: 79vw;
    background-size: cover;  
    align-items: flex-start;
    background-position: right;
   
    @media(min-width: 992px) {
        height:34vw;
        padding: 0;    
        align-items: flex-end;
        flex-direction: row;
    }

    #links {
        width: 44%;
        height: 50%;
        display: block;
        background-image: url('../images/logo_swt.svg');
        background-size: 80%;
        background-repeat: no-repeat;
        background-position: 50% 0%;
        // flex-grow: 0;
        margin-top: 7%;


        @media (min-width: 768px) {
            margin-top: 5%;
        }

        @media(min-width: 992px) {
            width: 30%;
            background-position: 50%;
            background-size: 70%;
            display: block;
            height: 100%;   
            margin-top: 0%;         
        }

    }

    #rechts {
        width: 95%;
        height: 100%;
        display: block;
        background-image: url('../images/menschen_start.png');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: bottom center;
        align-self: flex-end;

        @media (min-width: 576px) {
            width: 91%;

        }

        
        @media (min-width: 768px) {
            // align-self: center;
        }
        

        
        @media(min-width: 992px) {
            width: 70%;
            background-position: 0 100%;
            background-size: 85%;
        }        
        
    }
}

#mobile {
    display: flex;
    background-color: #E9E9E9;
    border-top: 1px solid white;

    p {
        color: #2C5440;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }

    @media(min-width: 768px) {
        display: none;
    }


}


#karte {
    background-image: url('../images/strassen_hintergrund.svg');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 1.5rem 0rem;
    margin-top: 1rem;

    @media(min-width:768px) {
        padding: 3rem 0rem;
    }

    @media (min-width: 992px) {
        margin-top: 2rem;
    }

    .buttons {
        flex-direction: row;
        justify-content: space-evenly;
        margin: 2rem 0rem;

        .zentriert {
            display: flex;
            justify-content: center;
            margin-bottom: 1.5rem;

            @media(min-width: 768px) {
                margin-bottom: 0rem;
            }

            a {
                text-decoration: none;

                h3 {
                    margin: 0;
                }
            }
        }
    }

    // .buttons {
    //     padding: 2rem 1rem;

    //     a {
    //         &.plain {
    //             text-decoration: none;
    //         }        
    //     }

    //     div {
    //         border-radius: 12%;
    //         padding: 0.5rem;
    //         display: flex;
    //         flex-direction: column;
    //         color: white;
    //         align-items: center;

    //         &#buttoneltern {
    //             background-color: $swtBlue;

    //         }

    //         &#buttonkinder {
    //             background-color: $swtMagenta;
    //         }

    //         img {
    //             max-width: 500px;
    //         }

    //         h3 {
    //             font-size: 1.1rem;
    //             margin-top: 0.5rem;
    //             font-family: $font-family-base-bold;
    //             padding: 0;

    //             @media(min-width:576px) {
    //                 font-size: 2rem;
    //                 margin-top: 2rem;
    //             }

    //             @media(min-width:768px) {
    //                 font-size: 2.25rem;
    //             }                

    //             @media(min-width:992px) {
    //                 font-size: 3rem;
    //             }
    //         }

    //         @media(min-width:576px) {
    //             border-radius: 15%;
    //             padding: 1rem 1.5rem 0.5rem 1.5rem;
    //         }               

    //         @media(min-width:768px) {
    //             padding: 2rem 3rem 1rem 3rem;
    //             border-radius: 12%;
    //         }    

    //         @media(min-width:1200px) {
    //             padding: 4rem 6rem 2rem 6rem;
    //             border-radius: 8%;
    //         }               
    //     }

    //     @media(min-width:576px) {
    //         padding: 2rem 1rem;
    //     }           

    //     @media(min-width:768px) {
    //         padding: 4rem 2rem;
    //     }         

    //     @media(min-width:992px) {
    //         padding: 6rem 3rem;
    //     }         

    //     @media(min-width:1200px) {
    //         padding: 8rem 4rem;
    //     } 

    //     @media(min-width:1400px) {
    //         padding: 10rem 5rem;
    //     } 
    // }
}