@import "../scss/variables.scss";
@import "../scss/animations.scss";

.audioBtn {
    position: absolute;
    bottom: 1rem;
    left: 1.75rem;
    border: 2px solid $light;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    z-index: 20;

    svg {
        width: 100%;
        height: auto;
    }

    @media(min-width: 768px){
        width: 48px;
        height: 48px;
        padding: 0.75rem;
    }

    &.audioPlaying {
        -webkit-animation: heartbeat 1s ease-in-out infinite both;
	    animation: heartbeat 1s ease-in-out infinite both;
    }

    &.muted {
        background-color: $muted;
    }
}