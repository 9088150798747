@font-face {
    font-family:EB Garamond;
    font-style:normal;
    font-weight:600;
    src:url(.../../../../fonts/EBGaramond-SemiBold.ttf) format("truetype");
}


#container_logoleiste {

    @media (min-width: 576px) {
        max-width: 100%;
    }

    @media (min-width: 768px) {
        max-width: 720px;
    }

    @media (min-width: 992px) {
        max-width: 960px;
    }

    @media (min-width: 1200px) {
        max-width: 1140px;
    }

    @media (min-width: 1400px) {
        max-width: 1320px;
    }

}

#logoleiste {
    margin-top: 1.5rem;
    margin-bottom: 2rem;
    justify-content: space-between;
    flex-direction: column;
    display: flex;

    @media(min-width: 768px) {
        flex-direction: row;
    }

    img {
        width: auto;
        height: 100%;
        max-height: 60px;
        margin-right: 0.5rem;

        @media(min-width: 768px) {
            max-height: 55px;
            margin-right: 1rem;
        }

        
        // @media(min-width: 768px) {
        //     max-height: 60px;
        // }

    }

    .ohneumbruch {
        display: flex;
        flex-direction: column;        
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: center;
        padding-bottom: 1.5rem;

        @media(min-width: 400px) {
            flex-direction: row;
            flex-wrap: nowrap; 
            align-items: center;
        }

        @media(min-width: 768px) {
            justify-content: space-between;
            padding-bottom: 0rem;
        }

        &.gibacht {
            padding-bottom: 0rem;
        }

        .box_im {
            text-align: center;
            margin-top: 2rem;

            @media(min-width: 400px) {
                margin-top: 0px;

                img {
                    max-height: 30px;

                    // @media(min-width: 768px) {
                    //     max-height: 35px;
                    // }
                }

            }

            span.logo_im {
                font-family: "EB Garamond", Georgia,"Times New Roman",Times,serif;
                display: inline-block;
                color: #3f3f3f;
                max-width: 300px;
                line-height: 1.2;
                font-size: 12px;
                margin-top: 2px;
    
                @media(min-width:400px) {
                    max-width: 200px;   
                }

                // @media(min-width: 768px) {
                //     max-width: 300px;
                // }
            }


        }


    }

    .lvw {
        display: flex;
        flex-direction: column;
        justify-content: center;


        @media(min-width: 768px) {
            justify-content: flex-end;
        }


        .col_logo {
            display: flex;
            justify-content: center;
            padding-bottom: 1.5rem;

            @media(min-width: 768px) {
                justify-content: flex-start;
                padding-bottom: 0rem;
            }


            img {
                margin-bottom: 0rem;
                max-height: 100px;
                width: 100%;
                // max-width: 300px;

                @media(min-width: 576px) {
                    margin-bottom: 0rem;
                    max-width: 100%;
                }

                @media(min-width: 768px) {
                    max-height: 100px;
                    max-width: 100%;
                }

                @media (min-width: 992px) {
                    // margin-bottom: 2rem;
                }
            
            }
        }


    }

    .gefoerdert {
        border-top: 1px solid #555;
        display: flex;
        flex-direction: column;        

        @media(min-width: 768px) {
            border-top: none;

        }

        p {
            text-align: center;
            margin-top: 1rem;
            // margin-bottom: 1rem;
            margin-bottom: 0.5rem;
            

            @media(min-width: 768px) {
                text-align: left;
                margin-top: 0rem;

            }


        }

    }


}