.relativ {
    position: relative;
    
    > img {
        position: relative;
        z-index: -1;
    }
}


.buttonbild {
    position: absolute;
    top: calc(50% - 1rem);
    width: 100%;
    height: calc(50% - 1rem);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    z-index: 0;

    @media(min-width: 400px) {
        top: calc(50% - 2rem);
        height: 50%;
    }

    @media(min-width: 768px) {
        top: 50%;
    }



    > div {
        &:first-of-type {
            margin-bottom: 0.5rem;

            @media(min-width: 400px) {
                margin-bottom: 2rem
            }

            @media(min-width: 768px) {
                margin-bottom: 1rem;
            }

            @media(min-width: 992px) {
                margin-bottom: 2rem;
            }
        }
    }

    a {
        text-decoration: none;
    }

    p {
        margin: 0;
    }
}