@import "../scss/variables.scss";

.ohneabstand {
    margin: 0rem;
}

a {
    text-decoration: none;
}

.button {
    border-width: 1px;
    border-style: solid;
    background-color: white;
    padding: 3px;
    color: var(--bs-body-color);
    border-radius: 50px;
    display: flex;
    flex-direction: row;
    width: 260px;
    align-self: center;
    cursor: pointer;

    @media(min-width: 400px) {
        width: 300px;
    }

    .icon {
        border-radius: 50%;
        padding: 5px;
        height: 100%;
        margin-right: 1rem;
        align-self:center;
        flex-shrink: 0;

        img {
            // height: 68px;
            height: 45px;
            width: auto;
            display: inline-block;
        }
    }

    h3 {
        margin: 0;

    }

    p.head {
        margin: 0;
        font-weight: 500;
        font-size: calc(1.3rem + 0.6vw);
        line-height: 1.2;
        font-family: $headings-font-family;

        @media (min-width: 1200px) {
            font-size: 1.75rem;
        }
    }
}