@import "../../../scss/variables.scss";

.wimmelbild {
    position: relative;   
    width: 100%;
    height: 100%;
    border-radius: 0.25rem;
    margin: 0;
    background-color: $light;
}

.wimmelCol {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    z-index: 1;
}

.scrollContainer {
    max-height: 100%;
    overscroll-behavior-y: contain;
}

.scrollArea {
    overflow: auto;
    width: 100%;
    height: 100%;

    @media(max-width: 992px) {
        
        &.borderTop {
            &::after {
                content: "";
                position: absolute;
                top: 0;
                bottom: unset;
                left: 0;
                height: 32px;
                width: 100%;
                background: linear-gradient(180deg, rgba(0,0,0,0.25) 0%, rgba(255,255,255,0) 100%);
            }
        }
    
        &.borderLeft {
            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 32px;
                height: 100%;
                background: linear-gradient(90deg, rgba(0,0,0,0.25) 0%, rgba(255,255,255,0) 100%);
            }
        }
    
        &.borderRight {
            &::before {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                width: 32px;
                height: 100%;
                background: linear-gradient(-90deg, rgba(0,0,0,0.25) 0%, rgba(255,255,255,0) 100%);
            }
        }
    
        &.borderBottom {
            &::after {
                content: "";
                position: absolute;
                top: unset;
                bottom: var(--bottomPos);
                left: 0;
                height: 32px;
                width: 100%;
                background: linear-gradient(0deg, rgba(0,0,0,0.25) 0%, rgba(255,255,255,0) 100%);
            }
        }
    }

}

.slideBtn {
    display: flex;
    position: absolute;
    align-items: center;
    z-index: -1;
    opacity: 0;
    transition: 500ms;
    margin: 0 !important;
    width: 36px;
    height: 36px;

    @media(min-width: 768px){
        width: 48px;
        height: 48px;
        padding: 0;
    }

    &.active {
        z-index: 5;
        opacity: 1;        
    }

    &.top {
        top: 1rem;
        left: calc(50% - 24px);
    }
    
    &.left {
        top: calc(50% - 24px - 75px);
        left: 1rem;
    }

    &.bottom {
        left: calc(50% - 24px);
        bottom: calc(128px + 2rem);
    }

    &.right {
        top: calc(50% - 24px - 75px);
        right: 1rem;
    }
    
    svg {
        width: 36px;
        height: 36px;

        @media(min-width: 768px){
            width: 48px;
            height: 48px;
        }
    }
}

.zoomBtn {
    display: flex;
    flex-direction: column;
    width: 48px;
    padding: 0 0.375rem;
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 5;

    @media(min-width: 768px){
        padding: 0;
    }

    .zoomIn, .zoomOut {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: auto;
        margin-bottom: 0.5rem;
        padding: 0.5rem;
        border: 3px solid $light;
        border-radius: 50%;
        background-color: $swtBlue;
        box-shadow: 0px 0px 5px 1px rgb(0 0 0 / 30%);
        transition: 500ms;        

        @media(min-width: 768px){
            padding: 0.75rem;
        }
    

        svg {
            width: 100%;
            height: auto;
            color: $light;
        }

        &.muted {
            cursor: auto;
            background-color: $muted;

            &:hover {
                background-color: $muted;
            }

            svg {
                color: $dark;
            }
        }
    }
}