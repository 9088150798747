.mainnavi {
    max-height: 100px;
    flex-direction: row !important;
}

#apptitel {
    display: flex;
    flex-direction: row;

    #titeltext {
        display: flex;
        flex-direction: column;
    }

}

.nav_element {
    color: white;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    font-size: 24px;
    position: relative;
    margin-right: 1rem;
    text-decoration: none;
    display: flex;
 

    span {
        display: none;
        
        @media(min-width:1200px){
            display: inline-block;
        } 

    }

    img {
        max-width: 35px;
        width: 35px;
        display: inline-block;  
        margin-right: 1rem; 

    }

    &:hover, &:focus-within {
        text-decoration: underline;
        color: white;
    }

    &:last-of-type {
        margin-right: 0;
    }

    &.logo {

        img {
            max-width: 35px;
            width: 35px;         
        }

        span {
            display: none;
            
            @media(min-width:768px){
                display: inline-block;
            }  
        
            &#untertitel {
                font-size: 0.8rem;
    
            }            
        }

    }


    &.eltern {
        img {
            margin-right: 0;

            @media(min-width:1200px){
                margin-right: 1rem;
            }
        }

        &.active, &:hover {
            img {
                content: url('../../images/icon_eltern_kreis.svg');
                
                @media(min-width:1200px){
                    margin-right: 1rem;
                }
            }
        
        }

    }

    &.kinder{
        border-right: 1px solid white; 
        padding-right: 1.5rem;    
        margin-right: 0;

        @media(min-width:1200px){
            margin-right: 1rem;
        }  
        
        img {
            margin-right: 0;

            @media(min-width:1200px){
                margin-right: 1rem;
            }
        }        
        
        &.active, &:hover {
            img {
                content: url('../../images/icon_kinder_kreis.svg');  

                @media(min-width:1200px){
                    margin-right: 1rem;
                }                
            }
        }

    }

    &.leicht {
        display: none;
        margin-left: 1rem;
        margin-right: 0rem;
        padding-right: 1.5rem;
        border-right: 1px solid white;

        @media(min-width:1200px){
            margin-left: 0rem;
        }  

        img {
            margin-right: 0rem;
            // max-width: 24px;
            // width: 24px;  
            margin-left: 0rem;

        }

        &.active, &:hover {
        
            img {
                content: url('../../images/icon_leichte-sprache_kreis.svg');
                max-width: 35px;
                width: 35px;  

            }
        }  
        
        @media(min-width: 768px) {
            display: flex;
        }
               
    }
  
    
}

// #games {
//     .eltern {
//         &.active, &:hover {
//             img {
//                 content: url('../../images/icon_eltern_kreis.svg');  
//             }
//         }
//     } 

//     .kinder{
        
//         &.active, &:hover {
//             img {
//                 content: url('../../images/icon_kinder_kreis.svg');  
//             }
//         }

//     }

//     &.leicht {

//         &.active, &:hover {
        
//             img {
//                 content: url('../../images/icon_leichte-sprache_kreis.svg');

//             }
//         } 
//     }
// }

.border_bottom {
    border-bottom: 2px solid white;
}
