@import "../scss/variables.scss";

.nextBtn {
    border: none;
    background: none;
    width: 100%;
    padding: 0.5rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 48px;
    font-size: 1.25rem;
    font-family: $font-family-base;
    transition: 500ms;

    &:hover {
        svg {
            color: #006b9b;
            transition: 500ms;
        }
    }
    
    &.inactive {
        svg {
            color: $muted;
        }
        
        &:hover {
            svg {
                color: $nocheingrau;
                transition: 500ms;
            }
        }
    }

    svg {
        width: 48px;
        height: 48px;
        color: $primary;
        background-color: $light;
        border: 2px solid $light;
        border-radius: 50%;
        box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.3);
        transition: 500ms;

        @media(min-width: 768px){
            border: 3px solid $light;
            width: 64px;
            height: 64px;
        }
    }
}
        