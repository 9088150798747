.nopadding {
    padding: 0 !important;
    margin: 0 !important;
    max-width: 100% !important;
}


.footernav {
    width: 100%;


    ul {
        list-style: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: auto;
        padding: 0;
        margin: 1rem 0rem;

        &.nomargin {
            margin: 0rem !important;        
        }

        @media(min-width: 768px) {
            margin: 1rem auto;
        }


        @media (min-width: 768px) {
            flex-direction: row;
            width: 100%;
        }    
    

        li {

            a {
                color: white;
                &:hover, &:focus-within {
                    text-decoration: underline;
                    color: white;
                }
            }
        }
    }
}