@import "../../scss/variables.scss";

.abstand {
    padding-top: 0.4rem;
    margin-left: 1rem;
}

.ganze_breite {
    width: 100%;
}


#schliessen {
    color: white;
    padding-top: 0;
    padding-bottom: 0;
}

.mitcursor {
    cursor: pointer;

    &:hover {
        div {
            text-decoration: underline;
        }
    }


}



#burgermenu {
    position: fixed;
    min-height: 100vh;
    height: 100%;
    background-color: white;
    display: none;
    right: 0;
    top: 0;
    width: 100%;
    max-width: 100%;
    overflow-y: auto;
    z-index: 1000;
    border: 1px solid $nav-link-color;

    @media (min-width: 576px) {
        max-width: 576px;
    }

    span {
        cursor: pointer;


        &.aufklappbar {
            content: "";
            background-image: url("../../images/icon_pfeil_blau_unten.svg");
            background-size: 15px auto;
            background-position: center;
            background-repeat: no-repeat;
            display: inline-block;
            width: 50px;
            border-left: 1px solid $nocheingrau;
            flex-shrink: 0;


            &.ohneborder {
                border: 0 !important;
            }            
        }
    }

    a {
        width: 100%;
    }




    &.show{
        display: flex;
        flex-direction: column;
    }


    ul {
        display: flex;
        flex-direction: column;
        background-color: white;
        padding: 0;
        list-style: none;  
        width: 100%;
        margin-bottom: 0px;
        
        &.aussen { 
            // border-left: 1px solid $nav-link-color;  
            // border-right: 1px solid $nav-link-color;
            margin-bottom: 0rem;
        }

        li {
            width: 100%;
            padding: 0;
            border-bottom: 1px solid $nav-link-color;
            display: flex;
            flex-direction: column;

            &.offen {
                > div {
                    > span {

                        &.aufklappbar {
                            background-image: url("../../images/icon_pfeil_blau_oben.svg");
                        }
                    }

                }
                
                ul {
                    &:first-of-type {
                        display: flex;
                    }

                    li {
                        ul {
                            display: none;

                            &:first-of-type {
                                display: none;
                            }
                        }

                        &.offen {
                            > div {
                                > span {
            
                                    &.aufklappbar {
                                        background-image: url("../../images/icon_pfeil_blau_oben.svg");
                                    }
                                }
            
                            }
                            
                            ul {                            
                                &:first-of-type {
                                    display: flex;
                                }
            
                                li {
                                    ul {
                                        display: none;
            
                                        &:first-of-type {
                                            display: none;
                                        }
                                    }
            
                                    &.offen {
                                        ul {
                                            display: flex;
            
                                            
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            div {
                display: flex;
                justify-content: space-between;
            }

            &:first-of-type {
                border-top: 1px solid $nav-link-color;
            }

            &:last-of-type {
                border-bottom: 0;
            }


            &.start {
                background-color: $swtBlue;
                color: white;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                padding-right: 0.5rem;

                a {
                    color: white;
                }

            }

            a {
                color: $nav-link-color;
                padding: 0.5rem 1rem;
                font-family: Saira-Medium;

                &.blue {
                    color: $swtBlue
                }

                &.magenta {
                    color: $swtMagenta;
                }

                &:hover{
                    text-decoration: underline;
                }
            }

            ul {
                // display: none;
                // padding: 0 1rem;

                li {

                    &.ohneborder {
                        border: 0 !important;
                    }

                    a {
                        padding-left: 1.5rem;
                        color: #1D1D1D;
                    }

                    ul {
                        display: flex;

                        li {

                            &.ohneborder {
                                border: 0 !important;
                            }

                            a {
                                padding-left: 2rem;
                                display: flex;
                                justify-content: flex-start;
                                color: $nav-link-color;
                                font-family: Saira;

                            }

                            ul {
                                display: none;
                            }                            
                        }
                    }
                }
            }

         
        }

        li#footernav { 
            // border-top: 1px solid $swtBlue;
            // margin-top: 2rem;

            .navbar {
                padding: 0 !important;
            }
            
            .navbar > .container {
                padding: 0 !important;
    
            }
            
            ul {
                li {
                    background-color: #E9E9E9;
                    border-bottom: 1px solid white;

                    &:first-of-type {
                        border-top: 0;
                    }


                    a {
                        padding: 0.5rem 1rem;
                        color: #484848;
                    }
                }
            }
    
        }   


    }


}