@import "../../scss/variables.scss";
@import "../../scss/animations.scss";

.wrapper {
    position: relative;
    // left: 0;
    // margin-bottom: 1rem;
    padding: 0;
    width: 100%;
    max-width: 100%;
    height: calc(100vh - 70px);
    
    @media(max-width: 1213px){
        margin: 0;
    }
    
    @media(min-width: 1214px){
        max-width: 1214px !important;
    }
}

.searchedElementRow {
    position: absolute;
    bottom: 0;
    left: 0;
    width: calc(100%);
    z-index: 102;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 1rem 0;
    background-color: rgba(255,255,255,0.95);

    @media(min-width: 768px){
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
    }
    
    span {
        font-family: $font-family-base-bold;
        color: $secondary;
        display: flex;
        justify-content: center;
        text-align: center;
    }
}

.searchedElementsWrap {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-top: 1rem;
    margin: 0;
    // flex-wrap: nowrap;
    // overflow-x: scroll;
    
    @media(min-width: 768px){
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: auto;
        margin: unset;
        overflow-x: auto;
    }

}

.searchedElementCol {
    display: flex;
    justify-content: center;
    // margin-top: 1rem;
    max-width: 25%;
    padding-left: 0.5rem;
    padding-right: 0.5rem;

    @media(min-width: 576px){
        padding-left: calc(var(--bs-gutter-x) * 0.5);
        padding-right: calc(var(--bs-gutter-x) * 0.5);
    }
}

.searchedElementWrapper {
    display: flex;
    align-items: center;
    // align-items: flex-start;

    .searchedElementImgWrapper {
        border-radius: 50%;
        overflow: hidden;
        border: 0.15rem solid $secondary;
        box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.3);
    
        @media(min-width: 768px){
            border-width: 0.25rem;
        }
    
        .searchedElement {
            // width: auto;
            // height: 100%;
            // width: 100%;
            // min-width: 48px;
            width: 100%;
            min-width: 28px;
            max-width: 64px;     
            height: auto;
    
            @media(min-width: 768px){
                width: 64px;     
            }
        }
    }

    svg {
        color: $swtMagenta;
        display: none;

        @media(min-width: 576px){
            display: flex; 
            min-width: 21px;
            min-height: 21px;
            margin-left: 0.25rem;
        }
    }
    
    &.found {
        
        .searchedElementImgWrapper {
            border-color: $success;
        }
    
        svg {
            color: $success;
        }
    }
}

.msgBox {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50vh;

    h1 {
        margin: 0;
        color: $light;
        z-index: 101;
        transition: 500ms;
    }

    &.correct {
        background-color: $success;

        h1 {
            color: $light;
        }
        svg {
            animation: spin 1s 1;
            color: $light;
        }
    }

    &.false {
        background-color: $danger;

        h1 {
            color: $light;
        }
        svg {
            animation: rotation 1s 1;
            color: $light;
        }
    }
    
    svg {
        width: 24px;
        height: 24px;
        color: $light;
        margin-right: 1rem;
        z-index: 101;
        transition: 500ms;
        
        @media(min-width: 576px){
            width: 48px;
            height: auto;
        }
    }
}

// .msgBox {
//     display: flex;
//     justify-content: center;
//     position: absolute;
//     z-index: 20;
//     bottom: 50%;
//     left: 0;
//     width: calc(100% - 1rem - 1vw);
//     margin: 0 calc(0.5rem + 0.5vw);
//     transition: 500ms;

//     .heading {
//         // margin-bottom: 1rem;
//         display: flex;
//         flex-direction: column;
//         justify-content: center;
//         align-items: center;
        
//         @media(min-width: 768px){
//             width: auto;
//             margin-bottom: 0;
//         }

//         @media(min-width: 992px){
//             justify-content: flex-end;
//         }

//         h1 {
//             @media(min-width: 992px){
//                 font-size: 2rem;
//             }
//         }

//         h2{
//             font-size: 1.3rem;

//             @media(min-width: 768px){
//                 font-size: 1.5rem;
//             }
//         }
//     }
// }

.gameEnd {
    position: absolute;
    top: calc(2rem);
    left: calc(50% - 37.5vw);
    width: 75vw;
    background-color: rgba(255,255,255,0.95);
    padding: 1rem;
    text-align: center;
    color: $secondary;
    border-radius: 0.25rem;
    z-index: 10;
    transition: 500ms;
    
    @media(min-width: 768px){    
        width: 600px;
        left: calc(50% - 300px);
        padding: 3rem;
        border-radius: 50%;
    }
}

.moreGames {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 1rem 0;
    padding-top: 1rem;
}

.audioBtn {
    bottom: unset !important;
    top: 1rem;
    left: 1.5rem !important;        
    
    @media(min-width: 768px){
        left: 1rem !important;        
    }
}