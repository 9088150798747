@import "../../scss/variables.scss";

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    background-color: $secondary;
    opacity: 0;
    z-index: -1;
    transition: 500ms;
    
    &.active {
      z-index: 100;
      opacity: 1;

      h1 {
        color: $light;
      }

      svg {
        color: $light;
      }
    }

    &.true {
      background-color: $success;
    }

    &.false {
      background-color: $danger;
    }

    @media(min-width:768px){
      &.small {
        top: unset;
        left: 1rem;
        bottom: 1rem;
        width: calc(100% - 2rem);
        height: auto;
        margin: 0 auto;
        border-radius: 1rem;
        box-shadow: 0 0 8px 1px rgb(0 0 0 / 30%);
      }
    }
}


@keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
}

@keyframes swing-out-top-bck {
    0% {
      -webkit-transform: rotateX(0deg);
              transform: rotateX(0deg);
      -webkit-transform-origin: top;
              transform-origin: top;
      opacity: 1;
    }
    100% {
      -webkit-transform: rotateX(-100deg);
              transform: rotateX(-100deg);
      -webkit-transform-origin: top;
              transform-origin: top;
      opacity: 0;
    }
}