@import "../../scss/variables.scss";

#tafel {
    background-color: $tafelfarbe;
    background-image: url('../../images/tafel_verkehr.jpg');      
    background-size: cover;  
    height:79vw;
    padding: 0;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    background-position: right;

    @media (min-width:992px) {
        height: 34vw;
        flex-direction: row;
        align-items: flex-end;
        background-position: left;
    }

    &.services {
        height: 20vw;
        min-height: 80px;
        background-position: top right;

        #menschen {
            width: 100%;
            align-items: center;
            justify-content: flex-start;
            flex-direction: row;

            @media (min-width: 992px) {
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-start;
            }

        }


    }
    

    #menschen {
        height: 100%;
        background-repeat: no-repeat;
        // background-size: auto 80%;
        // background-position: bottom 2vh left 16vw;  
        background-size: 84%;
        background-position: bottom left 50%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media (min-width: 430px) {
            // background-position: bottom 3vh left 14vw;  
        }

        @media (min-width: 576px) {
            // background-position: bottom 4.5vh left 10vw; 
        }

        @media (min-width: 768px) {
            // background-size: auto 70%;
            // background-position: bottom 2vw left 5vw;             
        }

        @media (min-width: 992px) {
            background-size: auto 70%;
            background-position: bottom 2vw left 5vw; 
            width: unset;
        }

        &.eltern {
            background-image: url('../../images/menschen_eltern.png');

        }

        &.gutzuwissen {
            background-image: url('../../images/menschen_gut_zu_wissen.png');
            // background-position: bottom 2vh left 25vw; 
            background-size: 55%;
            
            @media (min-width: 992px) {
                background-position: bottom 2vw left 15vw; 
                background-size: auto 70%;
            }
        }

        &.faq {
            background-image: url('../../images/menschen_faq.png');
            // background-position: bottom 2vh left 25vw;  
            background-size: 68%;

            @media (min-width: 576px) {
                // background-position: bottom 4vw left 20vw;  
            }

            @media (min-width: 992px) {
                background-position: bottom 4vw left 14vw;  
                background-size: auto 70%;
            }
        }

        &.grundlagen, &.versicherungsschutz  {
            background-image: url('../../images/menschen_grundlagen.png');
            // background-position: bottom 2vh left 25vw;  
            background-size: 68%;

            @media (min-width: 576px) {
                // background-position: bottom 4vw left 20vw;  
            }

            @media (min-width: 992px) {
                background-position: bottom 4vw left 14vw;  
                background-size: auto 70%;
            }
        }

        &.rechtliches {
            background-image: none;

            #trapez {
                justify-content: center;

                @media (min-width: 992px) {
                    width: 100%;
                }

                #dreieck {
                    display: none;
                }

                #trapeztext {
                    background-color: transparent;
                    padding: 0rem;
                    font-size: calc(3vw + 0.75vh);
                    width: 100%;

                    @media (min-width: 992px) {
                        // width: 50vw;
                        padding: 0 2vw 3vw 10vw;
                        padding-left: calc(20% + 8rem);
                        margin-top: -3rem;
                        font-size: min(45px, calc(3vw + 0.75vh));
                    }
                }
            }
        }


        #logo {
            width: auto;
            height: 29%;
            // margin: 2vw 5vw;
            margin-top: 7%;
            margin-left: 7%;

            @media (min-width: 992px) {
                width: 20%;
                height: auto;
                margin: 2vw 5vw;
            }

            

            img {
                width: auto;
                height: 100%;


                @media (min-width: 992px) {
                    max-width: 100%;
                    height: auto;
                }
            }
        }

        
        #trapez{
            display: flex; 
            flex-direction: column;

            #dreieck {
                border-left: 70vw solid $swtMagenta;
                border-top: 2vw solid transparent;
                border-bottom: 0px solid transparent;
                border-right: 10vw solid transparent;
                height: 1vw; 

                @media (min-width: 576px) {
                    border-left: 60vw solid $swtMagenta;
                }

                @media (min-width: 768px) {
                    border-left: 50vw solid $swtMagenta;
                }
            }
        
            #trapeztext {
                background-color: $swtMagenta;
                color:white;
                font-family: 'Saira', sans-serif;
                font-size: 5vw;
                padding: 0 2vw 1vw 5vw;
                width: 70vw; 

                @media (min-width: 576px) {
                    font-size: 4vw;
                    width: 60vw; 
                }

                @media (min-width: 768px) {
                    width: 50vw;
                    font-size: 3vw;
                }

                @media (min-width: 1200px) {
                    font-size: 2vw;
                }
            }

        }

    }



}     







