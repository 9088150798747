@keyframes spin{
    from{ 
        transform: rotate(0deg)
    }
    to{
        transform: rotate(359deg)
    }
}

@keyframes tilt-n-move-shaking {
    0% { transform: translate(0, 0) rotate(0deg); }
    3% { transform: translate(2px, 2px) rotate(2deg); }
    6% { transform: translate(0, 0) rotate(0deg); }
    9% { transform: translate(-2px, 2px) rotate(-2deg); }
    12% { transform: translate(0, 0) rotate(0deg); }
}

@keyframes wobble-hor-bottom {
    0%,
    100% {
      -webkit-transform: translateX(0%);
              transform: translateX(0%);
      -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%;
    }
    15% {
      -webkit-transform: translateX(-10px) rotate(-3deg);
              transform: translateX(-10px) rotate(-3deg);
    }
    30% {
      -webkit-transform: translateX(5px) rotate(3deg);
              transform: translateX(5px) rotate(3deg);
    }
    45% {
      -webkit-transform: translateX(-5px) rotate(-0.6deg);
              transform: translateX(-5px) rotate(-0.6deg);
    }
    60% {
      -webkit-transform: translateX(3px) rotate(1.4deg);
              transform: translateX(3px) rotate(1.4deg);
    }
    75% {
      -webkit-transform: translateX(-2px) rotate(-0.4deg);
              transform: translateX(-2px) rotate(-0.4deg);
    }
}

@keyframes heartbeat {
    from {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: center center;
              transform-origin: center center;
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    10% {
      -webkit-transform: scale(0.91);
              transform: scale(0.91);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    17% {
      -webkit-transform: scale(0.98);
              transform: scale(0.98);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    33% {
      -webkit-transform: scale(0.87);
              transform: scale(0.87);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    45% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
}

