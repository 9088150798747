#leichtesprache {
    a {
        word-break: break-all;
    }

    .zeile {
        margin-bottom: 2.5rem;

        &:first-of-type {
            img {
                margin-top: 0rem;

                @media (min-width: 768px) {
                    margin-top: 2rem;
                }

                @media (min-width: 992px) {
                    margin-top: 4rem;
                }
            }
        }
    }

    img {
        max-width: 100%;
        height: auto;
        margin-top: 2rem;
    }

    p {
        font-size: 1.2rem;
        margin-bottom: 1rem;
    }

    .untereinander {
        display: flex;
        flex-direction: column;

    }




    .kleiner {
        p {
            font-size: 1rem;
        }
    }

    .nebeneinander {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        @media (min-width: 992px) {
            flex-wrap: nowrap;
        }

        img {
            margin-left: 0rem;
            margin-top: 0rem;
            margin-bottom: 2rem;

            @media (min-width: 992px) {
                margin-left: 3rem;
            }
        }
    }
}