@import "../../scss/variables.scss";
@import "../../scss/animations.scss";

.wrapper {
    min-height: calc(100vh - 68px);
    display: flex;
    flex-direction: column;

    &.heightAuto {
        height: auto;
    }

    @media(min-width:768px){
        height: auto;
    }
}

.container {
    background-color: rgba(255,255,255,0.9);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    flex: 1 0;
    position: relative;
    overflow: hidden;
    // justify-content: space-between;
    // height: calc(100vh - 70px);
    // margin-bottom: 1rem;
    @media(min-width:576px){
        border-radius: 1rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
        flex: unset;
    }
    
    @media(min-width:768px){
        max-width: 768px;
    }
    
}

.headRow {
    display: flex;
    justify-content: center;
    position: relative;
    margin: 0.5rem 0.75rem 1.5rem;
    transition: margin 500ms;

    @media(max-width:768px){
        // margin: 0 calc(var(--bs-gutter-x) * 0.5);
        // margin-bottom: -1rem;
        // padding: 1rem;
        // background-color: $light;
        // width: calc(100% - 2 * calc(var(--bs-gutter-x) * 0.5));
        // border-top-right-radius: 1rem;
        // border-top-left-radius: 1rem;
    }

    @media(min-width:768px){
        margin: 0.5rem auto 1.5rem;
    }

    h1{
        margin: 0;
        // font-family: $font-family-serif;
        color: $light;
    }
    
    .feedbackWrap {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 48px;
        padding: 0.5rem;
        border-radius: 0.5rem;

        @media(min-width:768px){
            margin: 0 0.75rem;
            border-radius: 0;
            border-top-left-radius: 0.5rem;
            border-top-right-radius: 0.5rem;
            height: 62px;
        }

        h1 {
            color: $swtMagenta;
            // transition: 500ms;
        }

        &.correct {
            background-color: $success;

            h1 {
                color: $light;
            }
            svg {
                color: $light;
            }
        }

        &.false {
            background-color: $danger;

            h1 {
                color: $light;
            }
            svg {
                color: $light;
            }
        }
        
        svg {
            width: 24px;
            height: 24px;
            color: $light;
            margin-right: 1rem;
            transition: 500ms;
            
            @media(min-width: 576px){
                width: auto;
                height: 100%;
            }
        }

        &.rotate {
            svg {
                animation: spin 1s 1;
            }
        }
    }

    &.overlayActive {
        margin-top: 20vw;
        .feedbackWrap {
            h1 {
                color: $light;
                z-index: 101;
            }

            svg {
                color: $light;
                z-index: 101;
                width: 48px;
                height: auto;
            }
        }
    }
}

.imgRow {
    display: flex;
    // flex-direction: column;
    align-items: center;
    margin: 1rem 0;
    transition: 500ms;
    
    @media(min-width:430px){
        // flex-direction: row;        
        justify-content: center;
    }

    @media(min-width:992px){    
        margin-top: 0;

    }

    &.active {

        .imgWrap {
            width: auto;

            &.correctImg {

                img {
                    // border-color: $success;
                    transition: 500ms;
                }

                svg {
                    fill: $success;
                    // width: 25%;
                    // height: 25%;
                    // bottom: calc(8%);
                    // right: calc(8%);
                }
            }
            
            &.falseImg {

                svg {
                    fill: $danger;
                }

                img {
                    transition: 500ms;
                    filter: grayscale(0.75);
                }
            }

            img {
                width: 100%;

                @media(min-width:576px){
                    // max-height: 33vh;
                    transition: 500ms;
                }
            }
        }
    }

    .imgCol {
        margin: 0;
        height: auto;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 50%;

        // @media(max-width: 576px){
        //     width: 60%;
        // }

        // @media(max-width: 320px){
        //     width: 80%;;
        // }

        &.chosenImg, &.unchosenImg {
            .imgWrap {
                cursor: auto;
            }
        }

        // @media(min-width:576px){
        //     height: 100%;
        //     width: 50%;
        //     margin: 0;
        //     display: flex;
        //     flex-direction: column;
        //     justify-content: center;
        //     align-items: center;
        // }
    }

    .imgWrap {
        height: 100%;
        width: 100%; 
        position: relative;
        display: flex;
        justify-content: center;
        // overflow: hidden;
        cursor: pointer;
        transition: 500ms;
        z-index: 1;

        svg {
            position: absolute;
            bottom: 1rem;
            right: calc(50% - (1.25rem + 0.15vw));
            background: $light;
            border-radius: 50%;
            z-index: 5;
            width: calc(2.5rem + 0.3vw);
            height: calc(2.5rem + 0.3vw);
            max-width: 64px;
            max-height: 64px;
            border: 2px solid $light;
            
            @media(min-width: 768px){
                right: 1rem;
                width: calc(4rem + 0.5vw);
                height: calc(4rem + 0.5vw);
                max-width: 96px;
                max-height: 96px;
                border: 3px solid $light;
            }
        }

        .imgWrapper {
            display: flex;
            justify-content: center;
            height: 100%;
            width: 100%;
            max-width: 25vh;
            border-radius: 0.25rem;
            border: 0.25rem solid $light;
            box-shadow: 0 0 0.25rem 0 rgba(0,0,0,0.25);

            
            @media(min-width: 576px){
                max-width: 60vh;
            }
        }
        
        .img { 
            height: 100%;
            width: 100%;
            // width: auto;
            transition: 500ms;
        }
    }

    .chosenImg {
        .imgWrapper {
            border-color: $secondary;
        }
    }

}

.info {
    padding: 0 1rem;
    // background: $light;
    // border-radius: 1rem;
    // border: 3px solid;

    // &.false {
    //     border-color: $danger;
    // }

    // &.correct {
    //     border-color: $success;
    // }
}

.navBtn {
    transition: 500ms;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: unset;

    @media(min-width: 576px){
        flex: 1 0;
    }
}

.nextBtn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $muted;
    min-height: 48px;
    font-size: 1.25rem;
    font-family: $font-family-base;
    color: $light;
    transition: 500ms;

        &:hover {
            background-color: $muted;
            color: $light;

            svg {
                color: $light;
            }
        }
        
        &.active {
            background-color: $primary;
            color: $light;

            svg {
                color: $light;
            }
            
            &:hover {
                background-color: var(--bs-btn-hover-bg);
                color: $light;

                svg {
                    color: $light;
                }
            }
        }
        
        svg {
            width: 48px;
            height: 48px;
            margin-left: 1rem;
            color: $light;

            @media(min-width: 576px){
                width: 24px;
                height: 24px;
            }
        }
}

.successImg {
    display: flex;
    justify-content: center;
    
    svg {
        max-width: 600px;
        width: 80%;
        height: auto;
    }
}

.overviewRow {
    // margin-top: auto;
    margin-top: 1rem;
    
    @media(min-width: 576px){
        margin-top: unset;
    }
}

.flipBoxFront {
    max-width: 300px;
    width: 100%;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $swtBlue;
    margin: auto;

    h2 {
        color: $light;
        margin: 0;
    }
    
    @media(min-width:576px){
        background-color: unset;
        background-image: url("../../images/trapez-blue.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
    }

    @media(min-width: 768px){
       height: 96px;
    }
}

.audioBtn {
    bottom: unset !important;
    top: 1.85rem;
    left: 1.75rem !important;

    @media(min-width: 768px){
        top: 2rem;
     }
}

.container {
    &.solution {
        .audioBtn {
            left: 2.25rem !important;
        }
    }
}

.wobble {
    animation: wobble-hor-bottom 3.5s infinite;
}

.muted {
    cursor: auto !important;
    svg {
        color: $muted !important;
    }
}

@keyframes wobble-hor-bottom {
    0%,
    50%,
    100% {
      -webkit-transform: translateX(0%);
              transform: translateX(0%);
      -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%;
    }
    70% {
      -webkit-transform: translateX(-10px) rotate(-3deg);
              transform: translateX(-10px) rotate(-3deg);
    }
    75% {
      -webkit-transform: translateX(5px) rotate(3deg);
              transform: translateX(5px) rotate(3deg);
    }
    80% {
      -webkit-transform: translateX(-5px) rotate(-0.6deg);
              transform: translateX(-5px) rotate(-0.6deg);
    }
    85% {
      -webkit-transform: translateX(3px) rotate(1.4deg);
              transform: translateX(3px) rotate(1.4deg);
    }
    90% {
      -webkit-transform: translateX(-2px) rotate(-0.4deg);
              transform: translateX(-2px) rotate(-0.4deg);
    }
}
