@import '../../scss/variables.scss';

.testliste {
    padding-left: 1em;
}

.videohead {
    background-image: linear-gradient(#1E2846, #5A5A6E);
    padding: 0rem;
    color: white;
    overflow: hidden;

    @media(min-width:992px) {
        padding: 2rem;
    }

    video {
        width: calc(100%);
        margin-right: 0;

        @media(min-width:992px) {
            width: calc(100% - 2rem);
            margin-right: 2rem;           
        }
    }

    h1, h2 {
        color: white;
    }

    .infocol {
        display: flex;
        flex-direction: column;    
        justify-content: flex-end;
        padding: 1rem 1.5rem;
        

        @media(min-width:576px) {
            padding: 1rem 2rem;
            max-width: 540px;
            width: 100%;
            padding-right: calc(var(--bs-gutter-x) * 0.5);
            padding-left: calc(var(--bs-gutter-x) * 0.5);
            margin-right: auto;
            margin-left: auto;
        }

        @media(min-width:768px) {
            max-width: 720px;
        }

        @media(min-width:992px) {
            padding: 0rem 0rem 0rem 0rem;
            width: 33.33333333%;
            margin: 0rem;

        }       

        h1 {
            font-family: Saira-Medium;
            font-size: 2.2rem;
            margin: 1.5rem 0rem 2.5rem 0rem !important;
        }

        h2 {
            font-family: MavenPro;
            font-size: 1.5rem;
        }

        p.subhead {
            font-family: MavenPro;
            font-size: 1.5rem;
            margin-bottom: 0.5rem;
            line-height: 1.2;
            font-weight: 500;
        }
    }


}

p.absatz {
    margin-bottom: 1.5rem;
    // max-width: 75%;
}

.zwischenueberschrift {
        // border-bottom: 1px solid $swtBlue;
        padding-bottom: 0.5rem;
        margin-bottom: 0rem;


        @media(min-width: 576px) {
            // border-bottom: none;
            padding-bottom: 0rem;
            margin-bottom: 0.5rem;
        }
    }

.themenbereich {
    // border-bottom: 1px solid $swtBlue;
    margin-bottom: 2rem;
    padding-bottom: 0rem;
    margin-top: 5rem;


    @media(min-width: 768px) {
        border-bottom: none;
        margin-bottom: 0rem;
        padding-bottom: 2rem;

    }
}

.uebersicht {
    border-top: none;

    img {
        max-width: 100%;
    }

    div.normalcol, div.firstcol {
        padding-left: 0rem;
        padding-right: 0rem;
        margin-bottom: 2rem;

        @media(min-width: 578px) {
            padding-right: calc(var(--bs-gutter-x) * 0.5);           
        }

        div.inhalt {
            border: none;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            @media(min-width: 576px) {
                border-right: 1px solid $nocheingrau;
                border-top: 1px solid $nocheingrau;
            }

            h3, p {
                padding-right: calc(var(--bs-gutter-x) * 0.5);
                padding-left: calc(var(--bs-gutter-x) * 0.5);

                @media(min-width: 578px) {
                    padding-right: 1.5rem;
                    padding-left: 0rem;
                }
            }

            div.weiter {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                padding-right: 2rem;
            }

            @media(min-width: 992px) {
                border-top: none;
            }  
        }

        @media(min-width: 992px) {
            margin-bottom: 0rem;
        }
    }

    @media(min-width: 992px) {
        border-top: 1px solid $nocheingrau;
    }    
}

.kartengruppe {
    display: flex;
    flex-direction: row;
    // overflow-x: scroll;
    // overflow-y: hidden;
    scrollbar-width: none;
    overflow: visible;
    width: 100%;
    margin-bottom: 4rem;
    position: relative;
    z-index: 1;
    margin-left: 0px;
    transition: 0.5s margin-left;

    @media (min-width: 578px) {
        flex-wrap: nowrap;
    }

    
    @media(min-width: 992px) {
        margin-bottom: 2rem;
        margin-left: 0px;
    }

    .karte {
        border-radius: 0;
        border: 1px solid $cardgray !important;
        box-shadow:  0px 3px 6px #00000029;
        cursor: pointer;
        width: 100%;
        min-width: calc(300px - 3rem);
        margin-right: 2rem;
        max-width: calc(350px - 3rem);
        overflow: hidden;


        &> *, &:not(:last-child), &:not(:first-child) {
            border-radius: 0 !important;
        }

        @media(min-width: 768px) {
            min-width: calc(350px - 3rem);
        }

        @media(min-width: 992px) {
            margin-right: 1rem !important;
            min-width: 25%;
            width: 25%;
            flex-grow: 0;
        }

        img.headerbild {
            transition: transform 0.2s;

        }

        &:hover, &:focus-within {
            // border: 1px solid $swtBlue20 !important;
            // box-shadow:  0px 3px 6px $swtBlue20;

            &> * {
                color: $swtBlue;
            }

            img.headerbild {
                transform: scale(1.15, 1.15);

            }

            a img {
                content: url("../../images/icon_play_blau_invert.svg");
            }

        }

        p {
            margin-bottom: 2rem;
        }

        .kartenfuss {
            background-color: white;
            border-top: none;
            display: flex;
            justify-content: flex-end;
            padding-bottom: 1rem;
        }

    }

    .cardnavigation {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 100%;
        justify-content: space-between;
        position: absolute;
        top: 0px;
        left: 0px;


        @media (min-width: 992px) {
            display: none;
        }

        .arrowleft, .arrowright {
            // display: none;
            opacity: 0;
            transition: 0.4s opacity;
            cursor: pointer;
            width: 40px;
            margin: 0;
            padding: 0;
            background-color: $nocheingrau;
            background-image: url("../../images/icon_pfeil.svg");
            background-position: center center;
            background-size: 75%;
            background-repeat: no-repeat;
            z-index: 1;
            position: absolute;
            top: 0px;
            right: 0px;
        }

        .arrowleft {
            transform: rotate(180deg);
            left: 0px;

        }

        .arrowleft:hover, .arrowright:hover {
            // display: block;
            opacity: 0.8;
        }

    }
 

}

.relativ {
    position: relative;
    overflow: hidden;
}

.arrowleft, .arrowright {
    display: block;
    opacity: 0.8;
    transition: 0.4s opacity;
    cursor: pointer;
    width: 40px;
    margin: 0;
    padding: 0;
    background-color: $swtBlue;
    background-image: url("../../images/icon_pfeil.svg");
    background-position: center center;
    background-size: 65%;
    background-repeat: no-repeat;
    z-index: 3;
    position: absolute;
    top: 25%;
    // height: calc(100% - 0.5rem);
    height: 60px;
    border: 0px;

    @media (min-width: 992px) {
        display: none;
    }
}

.arrowright {
    right: 0;
}

.arrowleft {
    transform: rotate(180deg);
    left: 0;
    // display: none;
    opacity: 0;
}

.weiterlink {
    font-family: $font-family-base-bold;
    background-image: url("../../images/icon_pfeil_blau.svg");
    background-repeat: no-repeat;
    background-size: auto 15px;
    background-position: left center;
    padding-left: 15px;
    text-decoration: none;
    background-color: transparent;
    color: $swtBlue;
    box-shadow: none;
    border: none;

    &:hover, &:focus, &:focus-within {
        text-decoration: underline;
    }

}
