@import "../../scss/variables.scss";
@import "../../scss/animations.scss";

.container {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 68px);
    position: relative;
    
    @media(min-width: 768px){
        height: auto;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
}

.wrapRow {
    position: relative;
    background-color: rgba(255,255,255,0.9);
    box-shadow: 0 0 8px 1px rgb(0 0 0 / 30%);
    flex: 1 0;
    position: relative;
    min-height: 100%;
    padding-bottom: 1rem;
    
    @media(min-width: 768px){
        border-radius: 0.5rem;
        padding-bottom: 0;
    }
}

.avatarWrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    h1{
        display: none;

        @media(min-width: 768px){
            display: flex;
        }
    }
    
    svg {
        cursor: pointer;
        height: 35vh;
        width: auto;
    }
    
    @media(min-width: 768px){
        padding: 2rem 1rem;
        
        svg {
            height: 60vh;
            width: auto;        
        }
    }
}

.avatarCol {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.dressCol {
    position: relative;

    .menuRow {
        flex-direction: column;
        margin: 1rem;
        padding: 0.5rem;
        border-radius: 0.5rem;
    }

    .optionsRow {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        border-radius: 0.5rem;
        
        @media(min-width:768px){
            margin: 2rem 0;            
        }

        @media(min-width:992px){
            height: calc(100% - 2rem);
        }

        h2 {
            color: $secondary;
        }
    }

    .colors {
        // position: absolute;
        // top: calc(100% - 1rem);
        // left: calc(50% - (75%/2));
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        width: 100%;
        // margin-top: -1rem;
        margin-bottom: 1rem;
        background: $swtBlueLighter;
        padding: 1rem;
        border-bottom-right-radius: 0.5rem;
        border-bottom-left-radius: 0.5rem;
        z-index: 1;

        .colorBtn {
            border-radius: 50%;
            overflow: hidden;
            padding: 0;
            border: 2px solid $swtBlueLighter;
            margin-right: 0.5rem;

            &:last-child {
                margin-right: 0;
            }
                
            &.active {
                border: 2px solid $swtBlue;
            }

            .color {
                width: 24px;
                height: 24px;
                display: flex;
            }
        }

    }
}

.optionsBtn {
    position: relative;
    border: none;
    background-color: $light;
    margin: 0 0.5rem;
    border: 2px solid $light;
    border-radius: 0;
    transition: 500ms;

    @media(min-width: 1200px){
        min-width: 96px;

        img, svg {
            width: 64px;
            height: 64px;
        }
    }
    
    &:hover {
        // background-color: $swtBlueLight;
        background-color: $light;
        border: 2px solid $swtBlue;
        transition: 500ms;
    }

    &:first-child {
        margin-left: 0;
    }
    

    &:last-child {
        margin-right: 0;
    }

    &.times {

        svg {
            margin: 0.5rem;
            width: calc(48px - 1rem);
            height: calc(48px - 1rem);
            fill: $primary;
        }
    }

    &.active {
        border: 2px solid $swtMagenta;

        svg {
            fill: $swtMagenta;
        }
    }

    &.chosen {
        border: 2px solid $swtBlue;

        svg {
            fill: $swtBlue;
        }
    }
}

.btnRow {
    position: absolute;
    flex-direction: column;
    top: calc(var(--bs-gutter-x) * 0.5);
    right: calc(var(--bs-gutter-x) * 0.5);
    max-height: 40vh;

    .resetBtn, .randBtn, .checkBtn {
        width: 48px;
        height: 48px;
        box-shadow: 0 0 8px 1px rgb(0 0 0 / 30%);
        // border: 1px solid $black;
        margin-right: 0;
        margin-bottom: 1rem;

        &:last-child {
            margin-right: 0;
        }

        svg {
            width: 100%;
            height: auto;
        }
    }

    @media(min-width: 576px){
        flex-direction: row;

        .resetBtn, .randBtn, .checkBtn {
            margin-right: 1rem;
        }
    }

    .checkBtn {
        border: none;
        
        svg {
            color: $light;
        }
        
        &.muted {
            background-color: $nocheingrau;
        }
    }

    @media(max-width:767px){
        margin: 0;
    }

    @media(min-width:768px){
        position: relative;
        flex-direction: row;
        justify-content: flex-end;
        top: unset;
        right: unset;
        margin-top: 1rem;

        .resetBtn, .randBtn, .checkBtn {
            width: 64px;
            height: 64px;
            box-shadow: 0 0 8px 1px rgb(0 0 0 / 30%);
        }
    }
}

.msgBox {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: flex-start;
    color: $light;
    text-align: center;
    padding: 5vw 1rem 1rem;
    margin: 0 auto;
    
    &.small {
        padding-top: 1rem;
    }
    
    .msgBoxtext {
        display: flex;
        flex-direction: column;

        h1 {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        
        .smiley {
            width: 48px;
            height: 48px;
            margin-right: 1rem;

            &.rotate{
                animation: spin 1s 1;
            }
        }

        p {
            font-size: 1.5rem;
        }
    }
}

.galleryRow {
    background-color: rgba(255,255,255,0.9);
    padding-top: 1rem;
    padding-bottom: 1rem;
    min-height: calc(250px + 10vw);
    
    @media(min-width: 768px){
        box-shadow: 0 0 8px 1px rgb(0 0 0 / 30%);
        border-radius: 1rem;
        padding: 1rem;
    }
}

.galleryHeading {
    color: $secondary;
    text-align: center;
    margin: 2rem 0 1rem 0;
}

.avatarGallery {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    flex-wrap: wrap;

    @media(min-width: 768px){
        justify-content: flex-start;
    }

    .avatarGalleryCol {
        position: relative;
        width: 200px;
        height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        // box-shadow: 0 0 8px 1px rgb(0 0 0 / 30%);
        border: 6px dotted $swtMagenta20;
        border-radius: 0.25rem;
        padding: 0.5rem;
        margin: 0 0.5rem 1rem 0.5rem;
        cursor: pointer;
        transition: 500ms;

        &:hover {
            background-color: $light;
        }

        .avatarGalleryImg {
            height: 100%;
            width: auto;
        }

        .edit {
            position: absolute;
            top: 1rem;
            right: 1rem;
            width: 24px;
            height: 24px;
            color: $secondary;
        }
    }
}

.audioBtn {
    bottom: unset !important;
    top: 1rem !important;
    left: 1rem !important; 
    
    @media(min-width: 768px){
        bottom: 1rem !important;
        top: unset !important;
        left: 1rem !important; 
    }
}